import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../img/Logo.png";
import axios from "axios";
import Spinner from "./components/spinner";
import globalVariables from "../GlobalVariable";

const Login = () => {
  const [email, setEmail] = useState("aaron@azucarmultimedia.com2");
  const [password, setPassword] = useState("12345678");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await axios.post(
        `${globalVariables.domain}/user/login`,
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        const user_id = response.data.content.user_id;
        const json_web_token = response.data.content.json_web_token;

        // Guardar el token y el user_id en localStorage
        localStorage.setItem("token", json_web_token);
        localStorage.setItem("user_id", user_id);
        // Redirigir al usuario al Dashboard
        navigate("/Dashboard");
      } else {
        // Si la respuesta no es exitosa, mostrar el mensaje de error
        setError(response.data.content);
      }
    } catch (error) {
      setError("Incorrect credentials or connection error");
    } finally {
      setLoading(false); // Termina el loading al final
    }
  };
  return (
    <div className="flex min-h-screen flex-col justify-center items-center bg-white">
      <div className="mb-8 animate-wiggle animate-duration-200 animate-delay-1000 animate-ease-in-out">
        <img src={Logo} alt="Logo" width={400} />
      </div>
      <div className="bg-white p-8 rounded-lg w-96">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
              className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <div className="flex flex-col gap-2 justify-center items-center">
            <button
              type="submit"
              className="w-full text-white font-bold rounded-full p-2 bg-orange-500 hover:bg-orange-600 transition duration-300"
              disabled={loading}
            >
              {loading ? <Spinner /> : "Sign In"}
            </button>

            <a href="#" className="text-sm text-gray-600 hover:text-orange-500">
              Cannot access your account?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
