import React from "react";
import Logo from "../../img/Logo.png";
import Spinner from "./spinner";

const Loading = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <div className="animate-wiggle animate-duration-200 animate-delay-1000 animate-ease-in-out">
        <img src={Logo} width={400} alt="Logo" />
      </div>
      <Spinner />
    </div>
  );
};

export default Loading;
